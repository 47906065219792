import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

// import iconHome from '../assets/icons-home.png';
// import iconHomeHover from '../assets/icons-home--hover.png';

// import iconInfluencer from '../assets/icons-influencer.png';
// import iconInfluencerHover from '../assets/icons-influencer--hover.png';

// import iconUtms from '../assets/icons-add-link.png';
// import iconUtmsHover from '../assets/icons-add-link--hover.png';

// import iconEngagement from '../assets/icons-connected-people.png';
// import iconEngagementHover from '../assets/icons-connected-people--hover.png';

// import iconQRCode from '../assets/icons-qr-code.png';
// import iconQRCodeHover from '../assets/icons-qr-code--hover.png';

// import iconShipping from '../assets/icons-in-transit.png';
// import iconShippingHover from '../assets/icons-in-transit--hover.png';

// import iconData from '../assets/icons-combo-chart.png';
// import iconDataHover from '../assets/icons-combo-chart--hover.png';

// import iconInventory from '../assets/icons-warehouse.png';
// import iconInventoryHover from '../assets/icons-warehouse--hover.png';

// import iconLogout from '../assets/icons-logout.png';
// import iconLogoutHover from '../assets/icons-logout--hover.png';



// import moment from 'moment';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: this.props.location.pathname,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ pathName: this.props.location.pathname });
    }
  }
  render() {
    const { pathName } = this.state;
    const { user } = this.props;

    return (
      <React.Fragment>
        <Link to='/a/dashboard'>
          <span className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--home ${(pathName === '/a/dashboard') ? ('oklos-menu__nav__link-home-isActive') : ('')}`}>
            &nbsp;
          </span>
        </Link>
        <div className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--influencer ${(pathName.includes('/a/dashboard/qualification') || pathName.includes('/a/dashboard/am_outreach') || pathName.includes('/a/dashboard/outreach') || pathName.includes('/a/dashboard/youtube/report') || pathName.includes('/a/dashboard/youtube/emails') || pathName.includes('/a/dashboard/youtube/views') || pathName.includes('/a/dashboard/keywords')) ? ('oklos-menu__nav__link--isActive oklos-menu__nav__link-influencer-isActive') : ('')}`}>
          &nbsp;
          <div className="oklos-menu__nav__link--influencer--hover">
            <Link to='/a/dashboard/qualification' className={`${(pathName.includes('/a/dashboard/qualification')) ? ('link--isActive') : ('')}`}>Qualification</Link>
            <Link to='/a/dashboard/outreach' className={`${(pathName.includes('/a/dashboard/outreach')) ? ('link--isActive') : ('')}`}>Outreach</Link>
            <Link to='/a/dashboard/quick_outreach' className={`${(pathName.includes('/a/dashboard/quick_outreach')) ? ('link--isActive') : ('')}`}>Quick Outreach</Link>

            <Link to='/a/dashboard/new_influencer' className={`${(pathName.includes('/a/dashboard/new_influencer')) ? ('link--isActive') : ('')}`}>Add influencer</Link>

            <Link to='/a/dashboard/am_outreach' className={`${(pathName.includes('/a/dashboard/am_outreach')) ? ('link--isActive') : ('')}`}>Split outreach</Link>

            <Link to='/a/dashboard/influencer/search' className={`${(pathName.includes('/a/dashboard/influencer/search')) ? ('link--isActive') : ('')}`}>Search</Link>

            <Link to='/a/dashboard/youtube/views' className={`${(pathName.includes('/a/dashboard/youtube/views')) ? ('link--isActive') : ('')}`}>Estimator</Link>
            <Link to='/a/dashboard/youtube/emails' className={`${(pathName.includes('/a/dashboard/youtube/emails')) ? ('link--isActive') : ('')}`}>Process Emails MTurk</Link>
            <Link to='/a/dashboard/influencer/redirect' className={`${(pathName.includes('/a/dashboard/influencer/redirect')) ? ('link--isActive') : ('')}`}>Edit Redirects</Link>
            <Link to='/a/dashboard/keywords' className={`${(pathName.includes('/a/dashboard/keywords')) ? ('link--isActive') : ('')}`}>Scrapping Keywords</Link>
            <Link to='/a/dashboard/youtube/videos_info' className={`${(pathName.includes('/a/dashboard/youtube/videos_info')) ? ('link--isActive') : ('')}`}>Video information</Link>

            <Link to='/a/dashboard/tags/add' className={`${(pathName.includes('/a/dashboard/tags/add')) ? ('link--isActive') : ('')}`}>Add Tag/Campaign</Link>
          </div>
        </div>

        <div className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--data ${(pathName.includes('/a/dashboard/scrapping/reporting') || pathName.includes('/a/dashboard/scrapping/reporting') || pathName.includes('/a/dashboard/scrapping/reporting')) ? ('oklos-menu__nav__link--isActive oklos-menu__nav__link-influencer-isActive') : ('')}`}>
          &nbsp;
          <div className="oklos-menu__nav__link--data--hover">
            <Link to='/a/dashboard/scrapping/reporting' className={`${(pathName.includes('/a/dashboard/scrapping/reporting')) ? ('link--isActive') : ('')}`}>Scrapping Report</Link>
            <Link to='/a/dashboard/keyword/reporting' className={`${(pathName.includes('/a/dashboard/keyword/reporting')) ? ('link--isActive') : ('')}`}>Keywords Report</Link>
            <Link to='/a/dashboard/qualification/reporting' className={`${(pathName.includes('/a/dashboard/qualification/reporting')) ? ('link--isActive') : ('')}`}>Qualification Report</Link>
          </div>
        </div>

        <Link to='/a/dashboard/qrgenerator'>
          <span className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--qrcode ${(window.location.pathname === '/a/dashboard/qrgenerator') ? ('oklos-menu__nav__link--isActive oklos-menu__nav__link-qrcode-isActive') : ('')}`}>
            &nbsp;
          </span>
        </Link>
        <div className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--shipping ${pathName.includes('/a/dashboard/shipping/create') || pathName.includes('/a/dashboard/shipping/search') ? ('oklos-menu__nav__link--isActive oklos-menu__nav__link-shipping-isActive') : ('')}`}>
          &nbsp;

          <div className="oklos-menu__nav__link--shipping--hover">
            <Link to='/a/dashboard/shipping/create-v2' className={`${(pathName.includes('/a/dashboard/shipping/create-v2')) ? ('link--isActive') : ('')}`}>Create Shipping</Link>
            <Link to='/a/dashboard/shipping/search' className={`${(pathName.includes('/a/dashboard/shipping/search')) ? ('link--isActive') : ('')}`}>Search Order</Link>
          </div>
        </div>
        {/* <Link to='/a/dashboard/shipping'>
          <span className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--shipping ${(pathName.includes('/a/dashboard/shipping')) ? ('oklos-menu__nav__link--isActive oklos-menu__nav__link-shipping-isActive') : ('')}`}>
            &nbsp;
          </span>
        </Link> */}
        {/* <Link to='/a/dashboard/giftnote'>
          <span className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--gift ${(pathName.includes('/a/dashboard/giftnote')) ? ('oklos-menu__nav__link--isActive oklos-menu__nav__link-gift-isActive') : ('')}`}>
            &nbsp;
          </span>
        </Link> */}
        <div className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--data ${pathName.includes('/a/dashboard/data/inventory') ? ('oklos-menu__nav__link--isActive oklos-menu__nav__link-data-isActive') : ('')}`}>
          &nbsp;

          <div className="oklos-menu__nav__link--data--hover">
            <a href='https://analuisa-data.herokuapp.com/' target='_blank' rel="noopener noreferrer">Data BI</a>
            <Link to='/a/dashboard/data/inventory' className={`${(pathName.includes('/a/dashboard/data/inventory')) ? ('link--isActive') : ('')}`}>Inventory</Link>
          </div>
        </div>
        {["super-user", "admin"].includes(user?.role) ?
            <Link to='/a/dashboard/users/manage'>
              <span className={`mdl-navigation__link oklos-menu__nav__link material-icons-outlined ${(pathName.includes('/a/dashboard/users/manage')) ? ('oklos-menu__nav__link--isActive') : ('')}`}>
                people
              </span>
            </Link>
          : null}
        <Link to='/auth/logout'>
          <span className={`mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--logout ${(window.location.pathname === '/a/dashboard/auth/logout') ? ('oklos-menu__nav__link--isActive oklos-menu__nav__link-logout-isActive') : ('')}`}>
            &nbsp;
          </span>
        </Link>
      </React.Fragment>
    );
  }
}

export default withRouter(Navigation);
