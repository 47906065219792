import React, { Suspense, lazy } from "react";
import * as Sentry from "@sentry/react";

import Login from "./Login";
import Dashboard from "./Dashboard";
import DashboardV2 from "./Dashboard/DashboardV2";
import QRCodeGenerator from "./QRCodeGenerator";

import ShippingContainer from "./Shipping/ShippingContainer";
import ShippingSearch from "./Shipping/ShippingSearch";
import ModalHowToShipping from "./Shipping/ModalHowToShipping";
import Payment from "./Payment/Payment";
import Transaction from "./Transactions/Transaction";

import InfluencerComponent from "./InfluencerComponent";

import Channels from "./Channels";
import ChannelAverageViews from "./YouTube/ChannelAverageViews";
import VideosInformation from "./YouTube/VideosInformation";
import ModalHowToVideosInformation from "./YouTube/ModalHowToVideosInformation";
import ModalHowToNewInfluencer from "./YouTube/ModalHowToNewInfluencer";
import ChannelsEmails from "./YouTube/ChannelsEmails";
import NewInfluencer from "./YouTube/NewInfluencer";
import Redirect from "./Redirect";
import { OnlyAddManyInfluencers } from "./AddInfluencer/AddManyInfluencers";

import Qualification from "./Qualification/Qualification";

import OutreachSplit from "./Outreach/OutreachSplit";
import Outreach from "./Outreach/Outreach";
import QuickOutreach from "./Outreach/QuickOutreach";
import ModalOutreachPodcast from "./Outreach/ModalOutreachPodcast";
import ModalTypeformInformation from "./Outreach/currentDeal/ModalTypeformInformation";

import AddTagsCampaigns from "./AddTagsCampaigns/AddTagsCampaigns";

import Search from "./Search/Search";
import ModalSearchExport from "./Search/ModalSearchExport";

import Reporting from "./Reporting/Reporting";

import Reports from "./Reports/Reports";

import InstagramContainer from "./Instagram/InstagramContainer";
import InstagramToOutreach from "./Instagram/InstagramToOutreach";

import ModalYesNoHistory from "./ModalYesNoHistory";
import ModalQualificationHistory from "./ModalQualificationHistory";
import ModalConfirmation from "./ModalConfirmation";
import ModalWarn from "./Modals/ModalWarn";

import GiftNotes from "./GiftNotes";

import ScrappingKeywords from "./ScrappingKeywords";

// import TikTokContainer from "./TikTok/TikTokContainer";
// import TikTokYesNo from "./TikTok/TikTokYesNo";
// import TikTokToOutreach from "./TikTok/TikTokToOutreach";

import Navigation from "./Navigation";
import Products from "./Products";
import Inventory from "./Inventory";

import EngravablesContainer from "./Engravables/EngravablesContainer";

import Data from "./DataStreamlit";
import DataInventory from "./DataInventory";
import ModalHowToInventoryPlanning from "./ModalHowToInventoryPlanning";

import BrandAmbassador from "./BrandAmbassador";

import ScrappingContainer from "./ScrappingContainer";
import { BrowserRouter as Router, Route, Switch, Redirect as RouterRedirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";

import axios from "axios";
import Empty from "./Empty";
import InstagramYesNo from "./Instagram/InstagramYesNo";
import QualificationReporting from "./QualificationReporting/QualificationReporting";
import KeywordReporting from "./KeywordReporting/KeywordReporting";

import UserManagement from "./UserManagement";
import { IM_GROUP } from "dictionaries";
import ScrappingReporting from "./ScrappingReporting/ScrappingReporting";
import Loading from "./Loading";

const ShippingComponentV2 = lazy(() => import("./Shipping/ShippingComponentV2"));

const modals = {
  ModalHowToShipping: ModalHowToShipping,
  ModalHowToVideosInformation: ModalHowToVideosInformation,
  ModalHowToNewInfluencer: ModalHowToNewInfluencer,
  ModalHowToInventoryPlanning: ModalHowToInventoryPlanning,
  ModalYesNoHistory: ModalYesNoHistory,
  ModalQualificationHistory: ModalQualificationHistory,
  ModalConfirmation: ModalConfirmation,
  ModalSearchExport: ModalSearchExport,
  ModalOutreachPodcast: ModalOutreachPodcast,
  ModalWarn: ModalWarn,
  ModalTypeformInformation: ModalTypeformInformation,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        role: "",
        isLoading: true,
      },
      modal: null,
    };
    this.showModal = this.showModal.bind(this);
    this.handleModalClickOutside = this.handleModalClickOutside.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
  }
  fetchUser(error = 0) {
    const _this = this;
    axios
      .get("/auth/current_user")
      .then((res) => {
        console.log("current user");
        console.log(res.data);
        Sentry.setUser({
          id: res.data._id,
          email: res.data.email,
          fullName: `${res.data.firstName} ${res.data.lastName}`,
          role: res.data.role,
          teams: res.data.teams,
          googleId: res.data.googleId,
        });
        this.setState({ user: res.data });
      })
      .catch((err) => {
        console.log("C");
        console.log(err.response);
        if (err.response.status === 401) {
          console.log("logout");
          this.setState({ user: { role: "", isLoading: false } });
          return;
        } else {
          if (error > 15) {
            console.log("Error...");
            this.setState({ user: { role: "", isLoading: false } });
            return;
          } else {
            setTimeout(function () {
              const countErr = error + 1;
              _this.fetchUser(countErr);
            }, 100);
          }
        }
      });
  }

  showModal(modalName, props = null) {
    return new Promise((resolve, reject) => {
      this.setState({
        modal: {
          modalName: modalName,
          props: {
            ...props,
            resume: (data, err = null) => {
              if (err) return reject(err);
              return resolve(data);
            },
          },
        },
      });
    }).then((_res) => {
      this.setState({ modal: null });
      return _res;
    });
  }
  clearModal() {
    this.setState({ modal: null });
  }
  getModal() {
    const { modalName, props } = this.state.modal;
    const SelectedModal = modals[modalName];
    if (!SelectedModal) return;
    return <SelectedModal {...props} />;
  }
  handleModalClickOutside(e) {
    if (e.target === e.currentTarget) {
      this.state.modal.props.resume();
    }
  }

  render() {
    console.log("USER ROLE:", this.state.user.role);
    console.log("windowloc:", window.location.pathname);
    const deferedProps = {
      showModal: this.showModal,
      clearModal: this.clearModal,
      user: this.state.user,
    };
    return (
      <div className="mdl-layout mdl-js-layout mdl-layout--fixed-drawer mdl-layout--fixed-header oklos-menu">
        <Router>
          <div
            className={
              this.state.modal
                ? "oklos-menu__container--on-modal"
                : "oklos-menu__container"
            }
          >
            <div className="mdl-layout__drawer">
              <nav className="mdl-navigation oklos-menu__nav">
                {(() => {
                  // if (!this.state.user) {  }
                  // console.log(useLocation())
                  switch (this.state.user && this.state.user.role) {
                    case "super-user":
                    case "admin":
                    case "billing-user":
                    case "account-manager":
                    case "marketing":
                      return <Navigation user={this.state.user} />;
                    case "influencer":
                      // return <Empty />
                      return (
                        <a
                          className="mdl-navigation__link oklos-menu__nav__link oklos-menu__nav__link--logout_inf"
                          href="/auth/logout"
                        >
                          Logout
                        </a>
                      );
                    default:
                      return (
                        <a
                          className="mdl-navigation__link oklos-menu__nav__link"
                          href="/"
                        >
                          Please Login
                        </a>
                      );
                  }
                })()}
              </nav>
            </div>
            <main className="mdl-layout__content">
              <Suspense fallback={<div>Loading Oklos...</div>}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={() => (
                      <Login
                        user={this.state.user}
                        refresh={() => this.fetchUser()}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/a/dashboard"
                    component={() => <Dashboard user={this.state.user} />}
                  />
                  <Route
                    exact
                    path="/a/dashboard/v2"
                    component={() => {
                      if (this.state.user.isLoading) {
                        return <Loading />;
                      }
                      if (!IM_GROUP.includes(this.state.user.role)) {
                        return <RouterRedirect to="/" />;
                      }
                      return <DashboardV2 user={this.state.user} />
                    }}
                  />

                  <Route
                    exact
                    path="/a/influencer"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? () => <InfluencerComponent user={this.state.user} />
                        : () => <Empty />
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/qrgenerator"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? QRCodeGenerator
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/products"
                    component={
                      IM_GROUP.includes(this.state.user.role) ? Products : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/engravables"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? EngravablesContainer
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/products/inventory/:id"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? Inventory
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/channels"
                    component={
                      IM_GROUP.includes(this.state.user.role) ? Channels : Empty
                    }
                  />
                  <Route exact path="/a/dashboard/shipping">
                    {IM_GROUP.includes(this.state.user.role) ? (
                      <ShippingContainer {...deferedProps} />
                    ) : (
                      <Empty />
                    )}
                  </Route>
                  <Route
                    exact
                    path="/a/dashboard/shipping/create"
                    render={(routeProps) =>
                      IM_GROUP.includes(this.state.user.role) ? <ShippingComponentV2 {...routeProps} {...deferedProps} /> : <Empty />
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/shipping/create-v2"
                    render={() =>
                      IM_GROUP.includes(this.state.user.role) ? <RouterRedirect to="/a/dashboard/shipping/create" /> : <Empty />
                    }
                  />
                  <Route exact path="/a/dashboard/shipping/search">
                    {IM_GROUP.includes(this.state.user.role) ? (
                      <ShippingSearch {...deferedProps} />
                    ) : (
                      <Empty />
                    )}
                  </Route>
                  <Route
                    exact
                    path="/a/dashboard/qualification"
                    render={(props) =>
                      IM_GROUP.includes(this.state.user.role) ? (
                        <Qualification {...deferedProps} {...props} />
                      ) : (
                        Empty
                      )
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/influencer/search"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? () => <Search {...deferedProps} />
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/scrapping/reporting"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? () => <ScrappingReporting {...deferedProps} />
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/qualification/reporting"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? () => <QualificationReporting {...deferedProps} />
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/keyword/reporting"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? () => <KeywordReporting {...deferedProps} />
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/reporting"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? () => <Reporting {...deferedProps} />
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/influencer/redirect"
                    // component={IM_GROUP.includes(this.state.user.role) ? () => <div>Currently not available. Will return soon!</div> : Empty}
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? () => <Redirect {...deferedProps} />
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/am_outreach"
                    // component={IM_GROUP.includes(this.state.user.role) ? () => <div>Currently not available. Will return soon!</div> : Empty}
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? () => <OutreachSplit {...deferedProps} />
                        : Empty
                    }
                  />
                  <Route exact path="/a/dashboard/reports">
                    {IM_GROUP.includes(this.state.user.role) ? (
                      <Reports {...deferedProps} />
                    ) : (
                      <Empty />
                    )}
                  </Route>

                  {/* <Route path="/a/dashboard/outreach/:search?">
                {IM_GROUP.includes(this.state.user.role) ? <Outreach {...deferedProps} /> : <Empty />}
              </Route> */}

                  <Route
                    exact
                    path="/a/dashboard/outreach/:search?"
                    render={(props) =>
                      IM_GROUP.includes(this.state.user.role) ? (
                        <Outreach {...deferedProps} {...props} />
                      ) : (
                        Empty
                      )
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/quick_outreach/:search?"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? (props) => (
                            <QuickOutreach {...deferedProps} {...props} />
                          )
                        : Empty
                    }
                  />

                  <Route
                    exact
                    path="/a/dashboard/new_influencer"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? (props) => (
                            <NewInfluencer {...deferedProps} {...props} />
                          )
                        : Empty
                    }
                  />

                  <Route
                    exact
                    path="/a/dashboard/add_many_influencer"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? (props) => (
                            <OnlyAddManyInfluencers
                              {...deferedProps}
                              {...props}
                            />
                          )
                        : Empty
                    }
                  />

                  <Route
                    exact
                    path="/a/dashboard/keywords"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? (props) => (
                            <ScrappingKeywords {...deferedProps} {...props} />
                          )
                        : Empty
                    }
                  />

                  <Route
                    exact
                    path="/a/dashboard/youtube/views"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? ChannelAverageViews
                        : Empty
                    }
                  />

                  <Route
                    exact
                    path="/a/dashboard/tags/add"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? AddTagsCampaigns
                        : Empty
                    }
                  />

                  <Route exact path="/a/dashboard/youtube/videos_info">
                    {IM_GROUP.includes(this.state.user.role) ? (
                      <VideosInformation {...deferedProps} />
                    ) : (
                      <Empty />
                    )}
                  </Route>

                  <Route exact path="/a/dashboard/youtube/emails">
                    {IM_GROUP.includes(this.state.user.role) ? (
                      <ChannelsEmails {...deferedProps} />
                    ) : (
                      <Empty />
                    )}
                  </Route>

                  <Route
                    exact
                    path="/a/dashboard/instagram"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? InstagramContainer
                        : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/data"
                    component={
                      IM_GROUP.includes(this.state.user.role) ? Data : Empty
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/giftnote"
                    component={
                      IM_GROUP.includes(this.state.user.role)
                        ? GiftNotes
                        : Empty
                    }
                  />
                  <Route exact path="/a/dashboard/data/inventory">
                    {IM_GROUP.includes(this.state.user.role) ? (
                      <DataInventory {...deferedProps} />
                    ) : (
                      <Empty />
                    )}
                  </Route>
                  <Route exact path="/a/dashboard/brand_ambassador">
                    {["super-user"].includes(this.state.user.role) ? (
                      <BrandAmbassador {...deferedProps} />
                    ) : (
                      <Empty />
                    )}
                  </Route>
                  <Route
                    exact
                    path="/a/dashboard/users/manage"
                    component={(props) =>
                      ["super-user", "admin"].includes(this.state.user.role) ? (
                        <UserManagement {...deferedProps} {...props} />
                      ) : (
                        <Empty />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/payment"
                    component={(props) =>
                      ["super-user", "billing-user"].includes(
                        this.state.user.role
                      ) ? (
                        <Payment {...deferedProps} {...props} />
                      ) : (
                        <Empty />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/a/dashboard/transactions"
                    component={(props) =>
                      ["super-user", "billing-user"].includes(
                        this.state.user.role
                      ) ? (
                        <Transaction {...deferedProps} {...props} />
                      ) : (
                        <Empty />
                      )
                    }
                  />
                  <Route exact path="/a/dashboard/instagram/yesno">
                    {this.state.user.role !== "" ? (
                      <InstagramYesNo {...deferedProps} />
                    ) : (
                      <Empty />
                    )}
                  </Route>
                  <Route
                    exact
                    path="/a/dashboard/instagram/outreach"
                    component={
                      this.state.user.role !== "" ? InstagramToOutreach : Empty
                    }
                  />

                  <Route
                    exact
                    path="/a/dashboard/scrapping"
                    component={
                      this.state.user.role !== "" ? ScrappingContainer : Empty
                    }
                  />
                </Switch>
              </Suspense>
            </main>
          </div>
        </Router>
        {this.state.modal ? (
          <div
            className="oklos-menu__modal-container"
            onClick={this.handleModalClickOutside}
          >
            {this.getModal()}
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(null, actions)(App);
