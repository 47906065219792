import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAccountManagers, getInfluencers, isDealActive } from "utils";

import { ALDropdown } from "components/ALComponents";
import DealsList from "./DealsList";
import QuickSnapshot from "./QuickSnapshot";
import { FilterSearch, useFilteredDeals } from "./filterSearch/FilterSearch";
import useSortedDeals from "./useSortedDeals";

import "./DashboardV2.css";

function AMSelector({ currentAM, setCurrentAM }) {
  const [open, setOpen] = useState(false);
  const [allAMs, setAllAMs] = useState([]);

  useEffect(() => {
    getAccountManagers().then((ams) =>
      setAllAMs([
        ...ams,
        { id: "kalvin@analuisany.com", title: "Oklos Test (Catalin)" },
      ])
    );
  }, []);

  return (
    <div className={`am-selector ${open ? "am-selector--open" : ""}`}>
      {open ? (
        <>
          <span
            className="material-icons am-selector--close"
            onClick={() => setOpen(false)}
          >
            clear
          </span>
          <p>Preview as:</p>
          <ALDropdown
            value={currentAM}
            onChange={setCurrentAM}
            options={allAMs}
            useValue={"title"}
            useKey={"id"}
          />
          <br />
          <small>This selector will be removed after release</small>
        </>
      ) : (
        <span className="material-icons" onClick={() => setOpen(true)}>
          edit
        </span>
      )}
    </div>
  );
}

function DashboardV2({ user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentAM, setCurrentAM] = useState("kalvin@analuisany.com");
  const [influencers, setInfluencers] = useState([]);
  const deals = useMemo(
    () =>
      influencers.flatMap((influencer) =>
        influencer.deals
          .filter((deal) => isDealActive(deal))
          .toReversed()
          .map((deal, index) => ({
            ...deal,
            influencer,
            name: deal.name || `Deal ${index + 1}`,
          }))
      ),
    [influencers]
  );
  const [filteredDeals, setSearch] = useFilteredDeals(deals, currentAM);
  const [filteredAndSortedDeals, sortKey, sortDirection, setSort] =
    useSortedDeals(filteredDeals);

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    getInfluencers(currentAM)
      .then((influencersList) => {
        // Make sure we filter deals only for the current AM
        const influencersListOfAMOnly = influencersList
          .map((influencer) => {
            influencer.deals = influencer.deals.filter(
              (deal) => deal.am === currentAM
            );
            return influencer;
          })
          .filter((influencer) => influencer.deals.length > 0);
        setInfluencers(influencersListOfAMOnly);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentAM]);

  const onDealUpdate = useCallback((influencerId, updatedDeal) => {
    setInfluencers((prevInfluencers) => {
      return prevInfluencers.map((influencer) => {
        if (influencer._id === influencerId) {
          return {
            ...influencer,
            deals: influencer.deals.map((deal) =>
              deal._id === updatedDeal._id ? updatedDeal : deal
            ),
          };
        }
        return influencer;
      });
    });
  }, []);

  return (
    <div className="dashboard-v2__container">
      <div className="dashboard-v2__container__header">
        <h1>
          {new Date().getHours() < 13
            ? `Good morning, ${user.firstName}`
            : `Good afternoon, ${user.firstName}`}
        </h1>
        <FilterSearch setSearch={setSearch} />
        <AMSelector currentAM={currentAM} setCurrentAM={setCurrentAM} />
      </div>
      <QuickSnapshot isLoading={isLoading} influencers={influencers} />
      <DealsList
        isLoading={isLoading}
        deals={filteredAndSortedDeals}
        onDealUpdate={onDealUpdate}
        sortKey={sortKey}
        sortDirection={sortDirection}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardV2;
