import { useState } from "react";
import DashboardDeal from "./dealsList/DashboardDeal";
import TabbedNavigation from "./dealsList/TabbedNavigation";

import Loading from "components/Loading";

import "./DealsList.css";

export const FILTER_CRITERIA = {
  date_follow_up: "Today's Follow-ups",
  shipped: "Shipped",
  done: "Done",
  details: "Details",
  negociation: "Negotiation",
  hold: "Hold",
  shipment_received: "Shipment received",
  lead: "Lead",
};

const headerValues = [
  { title: "Influencer", sortKey: "influencer.emails[0]" },
  { title: "Deal name", sortKey: "name" },
  { title: "Date created", sortKey: "creation_date" },
  { title: "Last outreach", sortKey: "date_last_outreach" },
  { title: "Follow-up", sortKey: "date_follow_up" },
  { title: "Expected date", sortKey: "content[0].date_expected" },
  { title: "Payment", sortKey: "payment_status" },
  { title: "Actions" },
];

function DealsList({
  isLoading,
  deals,
  onDealUpdate,
  sortKey,
  sortDirection,
  setSort,
}) {
  const [selectedTab, setSelectedTab] = useState(
    Object.keys(FILTER_CRITERIA)[0]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!deals || deals.length === 0) {
    return null;
  }

  return (
    <div className="deal_list">
      <TabbedNavigation
        deals={deals}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <div className="deals_list__header">
        {headerValues.map(({ title, sortKey: headerSortKey }) => (
          <div
            className={`deals_list__header_item deals_list__header_item--${title
              .toLocaleLowerCase()
              .replaceAll(" ", "_")}`}
            key={title}
            onClick={() => headerSortKey && setSort(headerSortKey)}
          >
            {title}
            {headerSortKey === sortKey && (
              <span className="deals_list__header_item__sort material-icons">
                {sortDirection === "asc"
                  ? "keyboard_arrow_down"
                  : "keyboard_arrow_up"}
              </span>
            )}
          </div>
        ))}
      </div>
      {deals.map((deal) => (
        <DashboardDeal
          deal={deal}
          key={deal._id}
          selectedTab={selectedTab}
          onDealUpdate={onDealUpdate}
        />
      ))}
    </div>
  );
}

export default DealsList;
