import { useMemo } from "react";
import { format, isPast, isToday } from "date-fns";

import NotificationManager from "components/al_components/notification/NotificationManager";

import FollowUpDate from "./FollowUpDate";
import { getInfluencerName, getSocialMedia, getProfileImage } from "utils";

import { ReactComponent as GmailIcon } from "assets/svgs/gmail.svg";
import YoutubeIcon from "../../../assets/youtube_logo.png";
import PodcastIcon from "../../../assets/podcast_logo.png";
import InstagramIcon from "../../../assets/insta.png";
import TikTokIcon from "../../../assets/tiktok_logo.png";

import "./DashboardDeal.css";

function DashboardDeal({ deal, selectedTab, onDealUpdate }) {
  const showDeal = useMemo(() => {
    if (selectedTab === "date_follow_up") {
      const dateFollowUp = new Date(deal.date_follow_up);
      return isToday(dateFollowUp) || isPast(dateFollowUp);
    }
    return deal.status === selectedTab;
  }, [selectedTab, deal]);

  if (!showDeal) {
    return null;
  }

  const { influencer } = deal;
  const profilePictureSrc = getProfileImage(influencer) || "";
  const name = getInfluencerName(influencer);
  const socialMedia = getSocialMedia(influencer);
  const isGifted = deal.price === 0;

  return (
    <div className="deals_list__row" key={deal._id}>
      <div className="deals_list__content_item deals_list__content_item--influencer">
        <img
          src={profilePictureSrc}
          alt={influencer.name}
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <div className="deals_list__content_item_influencer_data">
          <span className="deals_list__content_item_influencer_data_name">
            {name}
          </span>
          <span className="deals_list__content_item_influencer_data_email">
            <span>{influencer.emails[0]}</span>
            <span
              className="deals_list__content_item_influencer_data_email--copy material-icons"
              onClick={() => {
                navigator.clipboard.writeText(influencer.emails[0]);
                NotificationManager.info("Copied!", null, 1000);
              }}
            >
              content_copy
            </span>
          </span>
        </div>
      </div>
      <span className="deals_list__content_item">
        <a
          href={`/a/dashboard/outreach?type=id&value=${influencer._id}&dealId=${deal._id}`}
        >
          {deal.name}
        </a>
      </span>
      <span className="deals_list__content_item deals_list__content_item--date_created">
        {format(new Date(deal.creation_date), "yyyy-MM-dd")}
      </span>
      <span className="deals_list__content_item deals_list__content_item--last_outreach">
        {deal.date_last_outreach
          ? format(new Date(deal.date_last_outreach), "yyyy-MM-dd")
          : ""}
      </span>
      <div className="deals_list__content_item deals_list__content_item--follow-up">
        <FollowUpDate
          date={deal.date_follow_up}
          dealId={deal._id}
          onFollowupDateUpdate={({ updatedDeal }) =>
            onDealUpdate(influencer._id, updatedDeal)
          }
        />
      </div>
      <span className="deals_list__content_item deals_list__content_item--expected_date">
        {deal.content[0]?.date_expected
          ? format(new Date(deal.content[0]?.date_expected), "yyyy-MM-dd")
          : ""}
      </span>
      <div className="deals_list__content_item deals_list__content_item--payment">
        {isGifted ? "N/A" : `${deal.payment_status}%`}
      </div>
      <div className="deals_list__content_item deals_list__content_item--social">
        <a
          href={`https://mail.google.com/mail/u/0/#search/${influencer.emails[0]}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GmailIcon width="25" />
        </a>
        {socialMedia.youtube && (
          <a
            href={`https://www.youtube.com/channel/${
              typeof socialMedia.youtube === "string"
                ? socialMedia.youtube
                : socialMedia.youtube.channelId
            }/videos`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={YoutubeIcon}
              alt="youtube"
              className="deals_list__content_item_social__youtube"
            />
          </a>
        )}
        {socialMedia.instagram && (
          <a
            href={`https://instagram.com/${
              typeof socialMedia.instagram === "string"
                ? socialMedia.instagram
                : socialMedia.instagram.profileId
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={InstagramIcon} alt="instagram˝" />
          </a>
        )}
        {socialMedia.tiktok && (
          <a
            href={`https://www.tiktok.com/@${
              typeof socialMedia.tiktok === "string"
                ? socialMedia.tiktok
                : socialMedia.tiktok.profileId
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TikTokIcon} alt="tiktok" />
          </a>
        )}
        {socialMedia.podcast && (
          <a
            href={socialMedia.podcast}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={PodcastIcon} alt="podcast" />
          </a>
        )}
        <a
          href={`/a/dashboard/outreach?type=id&value=${influencer._id}&dealId=${deal._id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="deals_list__content_item_social__launch"
        >
          <span className="material-icons">launch</span>
        </a>
      </div>
    </div>
  );
}

export default DashboardDeal;
