import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Redirect } from 'react-router-dom'
import Loading from "./Loading";
import { IM_GROUP } from "dictionaries";
import "./Dashboard.css";

import logoInfluencer from '../assets/influencer_icons.png';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userGoogleIdFromRes: this.props.user,
      countInfluencersScrap: false,
      countInfluencersQualification: false,
      countKeywordsToScrap: null,
      creditsNeverBounce: null,
    };
  }

  componentDidMount() {

    // Number of channel to qualify
    axios.get('/api/v1/influencer_created').then((r) => {
      this.setState({ countInfluencersScrap: r.data.count || "0" });
    }).catch((err) => {
      if (err.code == "ECONNABORTED") {
        this.setState({ countInfluencersScrap: "+50000" });
      }
    });

    axios.get("/api/v1/influencers_full_count?status=qualification").then((r) => {
      if (r.status == 200) {
        this.setState({ countInfluencersQualification: r.data.count });
      }
    });

    axios.get("/api/v1/redis/keywords", { params: { count_only: true } }).then((r) => {
      if (r.status === 200) {
        this.setState({
          countKeywordsToScrap: r.data.count,
        });
      }
    });

    axios.get("/api/v1/neverbounce/info").then((r) => {
      if (r.status === 200) {
        this.setState({
          creditsNeverBounce: r.data.result.credits_info.paid_credits_remaining,
        });
      }
    })
  }


  render() {
    // const { userGoogleIdFromRes, keywordToScrap, sponsorshipYESNO, sponsorshipAdd, sponsorshipOutreach, sponsorshipXML, cocreationYESNO, cocreationOutreach, instagramYESNO, instagramOutreachEmail, instagramOutreachNoEmail } = this.state;
    const { userGoogleIdFromRes, countInfluencersScrap, countInfluencersQualification, countKeywordsToScrap, creditsNeverBounce } = this.state;
    return (
      <div style={{ textAlign: "center" }}>
        {(IM_GROUP.includes(userGoogleIdFromRes?.role)) ? (
          <div className="dashboard__container">
            <div className="dashboard__container__header">
              <h1>
                {(new Date().getHours() < 13) ? (
                  `Good morning, ${this.state.userGoogleIdFromRes.firstName}`
                ) : (
                  `Good afternoon, ${this.state.userGoogleIdFromRes.firstName}`
                )}
              </h1>
            </div>
            <div className="dashboard__container__content">
              <div className="dashboard__container__content__influencer">
                <div className="dashboard__data__title">
                  <img src={logoInfluencer} alt="logo youtube" />
                  <h2>Scrapping informations</h2>
                </div>
                <div className="dashboard__data__content">
                  <div className="dashboard__container__content__influencer_detail">
                    <p className="dashboard__data__main--title"># Influencer to Scrap</p>
                    <p className="dashboard__data__main--value">{(countInfluencersScrap) ? ((countInfluencersScrap).toLocaleString('us-US')) : (<Loading />)}</p>
                  </div>
                  <div className="dashboard__container__content__influencer_detail">
                    <a href="/a/dashboard/qualification" className="dashboard__data__main--title"># Yes/No Qualification</a>
                    <p className="dashboard__data__main--value">{(countInfluencersQualification || countInfluencersQualification === 0) ? ((countInfluencersQualification).toLocaleString('us-US')) : (<Loading />)}</p>
                  </div>
                  <div className="dashboard__container__content__influencer_detail">
                    <a href="/a/dashboard/keywords" className="dashboard__data__main--title"># Keywords to Scrap</a>
                    <p className="dashboard__data__main--value">{(countKeywordsToScrap) ? ((countKeywordsToScrap).toLocaleString('us-US')) : (<Loading />)}</p>
                  </div>
                  <div className="dashboard__container__content__influencer_detail">
                    <p className="dashboard__data__main--title">NeverBounce Credits remaining</p>
                    <p className="dashboard__data__main--value">{creditsNeverBounce ? creditsNeverBounce : <Loading />}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          (userGoogleIdFromRes && userGoogleIdFromRes.role == "") ? (
            <Redirect to='/' />
          ) : (
            <Loading />
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth };
};
export default connect(mapStateToProps)(Dashboard);